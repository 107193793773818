<template>
  <div class="displayImages_container">
    <div
      class="displayImages_select_block_container global_container_center"
      v-if="images.length == 0"
    >
      <div class="displayImages_select global_container_center">
        <div
          class="displayImages_select_block global_container_center"
          @click="selectFile"
        >
          <img :src="require('@/assets/images/image_add.png')" alt="" />
          <span>点此上传{{ this.type || "" }}图片（最多四张）</span>
        </div>
      </div>
    </div>
    <div
      class="displayImages_select_not_more_four"
      v-else-if="images.length < 4"
    >
      <div
        class="displayImages_select_not_more_four_item"
        v-for="(item, index) in images"
        :key="getRandomIndex(index)"
      >
        <CustomImage :src="item"></CustomImage>
        <el-icon
          class="displayImages_select_not_more_four_item_close"
          v-if="isEdit"
          @click="removeFile(index)"
        >
          <Close
        /></el-icon>
      </div>
      <div
        class="global_container_center displayImages_select_not_more_four_item_add"
        v-if="isEdit"
        @click="selectFile"
      >
        <el-icon> <Plus /></el-icon>
      </div>
    </div>
    <div class="displayImages_select_four" v-else>
      <div class="displayImages_select_four_item">
        <div
          class="displayImages_select_four_item_image"
          v-for="(item, index) in images.slice(0, 2)"
          :key="getRandomIndex(index)"
        >
          <CustomImage :src="item"></CustomImage>
          <el-icon
            class="displayImages_select_not_more_four_item_close"
            v-if="isEdit"
            @click="removeFile(index)"
          >
            <Close
          /></el-icon>
        </div>
      </div>
      <div class="displayImages_select_four_item">
        <div
          class="displayImages_select_four_item_image"
          v-for="(item, index) in images.slice(2)"
          :key="getRandomIndex(index)"
        >
          <CustomImage :src="item"></CustomImage>
          <el-icon
            class="displayImages_select_not_more_four_item_close"
            v-if="isEdit"
            @click="removeFile(index + 2)"
          >
            <Close
          /></el-icon>
        </div>
      </div>
    </div>
    <input
      type="file"
      @change="changeFile"
      :v-if="resetFile"
      v-show="false"
      capture="environment"
      multiple
      accept="images/*"
      ref="selectFile"
    />
  </div>
</template>
<script>
import { ElIcon, ElLoading } from "element-plus";
import { Plus, Close } from "@element-plus/icons";
import CustomImage from "@/components/unit/customImage";
import weixin from "@/common/weixin";
export default {
  name: "displayImages",
  components: {
    CustomImage,
    ElIcon,
    Close,
    Plus,
  },
  data() {
    return {
      resetFile: true,
      loading: false,
      maxCount: 4,
    };
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    images: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    meetId: Number,
  },
  methods: {
    getCount() {
      let list = this.images.filter((x) => !!x);
      return this.maxCount - list.length;
    },
    selectFile() {
      if (!this.isEdit || this.loading) return;
      this.loading = true;
      let loadingItem = this.$tips.customLoading();
      weixin
        .selectFile(this.getCount(), this.meetId)
        .then((files) => {
          console.log(files);
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          loadingItem.close();
          this.$emit("addFile", files);
        })
        .catch((err) => {
          console.log(err, "报错了");
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          loadingItem.close();
        });
    },
    getRandomIndex(index) {
      return Math.random() * (index + 1);
    },
    changeFile(e) {
      let files = e.target.files;
      this.resetFile = false;
      this.resetFile = true;
      if (files.length == 0) return;
      let data = new FormData();
      this.loading = true;
      for (let index = 0; index < files.length; index++) {
        data.append("file", files[index]);
      }
      let loadingItem = ElLoading.service({
        lock: true,
        text: "图片上传中，请稍后",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$axios
        .post(this.$urls.meet.upload, data)
        .then((res) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          loadingItem.close();
          this.$emit("addFile", res.url);
        })
        .catch((err) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          loadingItem.close();
        });
    },
    removeFile(index) {
      this.$emit("removeFile", index);
    },
  },
};
</script>
<style>
.displayImages_container {
  height: 100%;
  width: 100%;
}

.displayImages_select_not_more_four {
  display: flex;
  height: 100%;
}
.displayImages_select_not_more_four_item {
  flex: 1 0 0;
  height: 100%;
  margin-right: 1vw;
  position: relative;
}
.displayImages_select_not_more_four_item:last-child {
  margin: 0;
}
.displayImages_select_not_more_four_item_add {
  width: 8vw;
  color: #9e004b;
}
.displayImages_select_not_more_four_item_close {
  position: absolute;
  font-size: 6vw;
  right: 0;
  top: 0;
  color: #fff;
}
.displayImages_select_four {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.displayImages_select_four_item {
  flex: 1 0 0;
  display: flex;
}
.displayImages_select_four_item:last-child {
  margin-top: 1vw;
}
.displayImages_select_four_item_image {
  flex: 1 0 0;
  position: relative;
  background: red;
  overflow: hidden;
}
.displayImages_select_four_item_image:first-child {
  margin-right: 1vw;
}
.displayImages_select_block_container {
  height: 100%;
  width: 100%;
  background: #fff;
}
.displayImages_select_block {
  display: flex;
  flex-direction: column;
  color: red;
  width: 72.66vw;
  height: 52.26vw;
  border: 1px solid #e6e6e6;
}
.displayImages_select_block img {
  width: 12vw !important;
  height: auto !important;
}
.displayImages_select_block span {
  margin-top: 4vw;
}

.displayImages_select {
  height: 100%;
  width: 100%;
}
.displayImages_select_block {
  display: flex;
  flex-direction: column;
  color: red;
  width: 72.66vw;
  height: 52.26vw;
  border: 1px solid #e6e6e6;
}
.displayImages_select_block img {
  width: 12vw !important;
  height: auto !important;
}
.displayImages_select_block span {
  margin-top: 4vw;
}
</style>