<template>
  <div class="commonTemplate_container">
    <div
      class="commonTitle global_text_content"
      :class="{ ...getTitleClass() }"
    >
      <el-input
        v-model="data.title"
        placeholder="点此输入标题"
        @input="changeTitle"
        type="textarea"
      />
      <div class="global_text_count">{{ getCount("title") }}</div>
    </div>
    <div class="commonTemplate_content global_text_content">
      <el-input
        v-model="data.content"
        @input="changeContent"
        placeholder="点此输入正文"
        type="textarea"
      />
      <div class="global_text_count global_text_count_content">
        {{ getCount("content") }}
      </div>
    </div>
    <div class="commonTemplate_images">
      <DisplayImages
        :isEdit="true"
        @addFile="addFile"
        @removeFile="removeFile"
        :images="data.images"
        :meetId="meetId"
        type="病例"
      />
    </div>
  </div>
</template>
<script>
import { ElInput } from "element-plus";
import CustomImage from "@/components/unit/customImage";
import DisplayImages from "@/components/unit/displayImages";
import { ElIcon } from "element-plus";
import { Plus, Close } from "@element-plus/icons";
export default {
  name: "commonTemplate",
  props: {
    pageItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    meetInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    meetId: Number,
  },
  components: {
    ElInput,
    CustomImage,
    DisplayImages,
    ElIcon,
    Close,
    Plus,
  },
  data() {
    return {
      data: {
        title: "",
        content: "",
        images: [],
      },
      keys: ["title", "content", "images"],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData(dataKey, item) {
      dataKey = dataKey || "class_content";
      this.keys.forEach((key, index) => {
        if (key == "images") {
          this.data[key] =
            item ??
            this.pageItem.content[index][dataKey]
              .split(",")
              .filter((x) => x != "");
        } else {
          this.data[key] = item ?? this.pageItem.content[index][dataKey];
        }
      });
    },
    getProject() {
      let key = this.meetInfo?.main_color || "9e004b";
      return this.$config.theme[key].name;
    },
    doEdit() {},
    clearInfo() {
      this.initData("default_content", "");
    },
    restore() {
      this.initData("default_content");
    },
    getCount(key) {
      let data = this.$tools.dealWithText(
        this.data[key],
        this.getCfg(key, "template_max")
      );
      return `${data.count}/${this.getCfg(key, "template_max")}`;
    },

    getCfg(key, attr) {
      let index = this.keys.indexOf(key);
      return this.pageItem.content[index][attr];
    },

    changeTitle() {
      this.data.title = this.$tools.dealWithText(
        this.data.title,
        this.getCfg("title", "template_max")
      ).content;
    },
    changeContent() {
      this.data.content = this.$tools.dealWithText(
        this.data.content,
        this.getCfg("content", "template_max")
      ).content;
    },
    formatData() {
      let data = [];
      let code = 200;
      for (let index = 0; index < this.keys.length; index++) {
        let key = this.keys[index];
        if (key == "images") {
          data.push(this.data[key].join(","));
          continue;
        }
        data.push(this.data[key]);
      }
      if (
        this.data.title == "" &&
        this.data.content == "" &&
        this.data.images.length == 0
      ) {
        this.$tips.error({ text: "请至少填写一项值" });
        code = 400;
      }
      data = data.map((item, index) => {
        return {
          class_content: item,
          update_status: 1,
          id: this.pageItem.content[index].id,
        };
      });
      return { code, data };
    },
    addFile(urls) {
      this.data.images = this.data.images.concat(urls);
    },
    removeFile(index) {
      this.data.images.splice(index, 1);
    },
    getData(callback) {
      let data = this.formatData();
      callback(data);
    },
    getTitleClass() {
      let project = this.getProject();
      let cls = {};
      switch (project) {
        case "pubeixi":
          cls["pubeixi_commonTemplate_title"] = true;
          break;
        default:
          cls["commonTemplate_title"] = true;
          break;
      }
      return cls;
    },
  },
};
</script>
<style>
.commonTemplate_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.commonTemplate_title {
  background: linear-gradient(#9e004b, #a0044e, #cc6f9c);
  height: 16.4vw;
}
.pubeixi_commonTemplate_title {
  background: linear-gradient(#004890, #4f7db5);
  height: 16.4vw;
}
.commonTitle textarea {
  background: transparent;
  border: none;
  color: #fff;
}
.commonTitle textarea::placeholder {
  color: #fff;
}
.commonTitle .el-input__count {
  background: transparent;
  color: #fff;
}
.commonTemplate_content textarea {
  height: 22vw;
}

.commonTemplate_content textarea::placeholder {
  color: red;
}
.commonTemplate_images {
  flex: 1 0 0;
  overflow: hidden;
}
.commonTemplate_content {
  border-bottom: 1px solid #e6e6e6;
}
.commonTemplate_select {
  height: 100%;
  width: 100%;
}
.commonTemplate_select_block {
  display: flex;
  flex-direction: column;
  color: red;
  width: 72.66vw;
  height: 52.26vw;
  border: 1px solid #e6e6e6;
}
.commonTemplate_select_block img {
  width: 12vw !important;
  height: auto !important;
}
.commonTemplate_select_block span {
  margin-top: 4vw;
}
</style>